.ant-tabs {
    position: relative;
    top: 0;
}

.ant-tabs-nav-list {
    display: flex;
    align-items: center;
    width: 460px;
    font-size: 16px;
}

.ant-tabs-tab {
    width: 50%;
    justify-content: center;
    font-size: 16px;
    font-weight: 800;
    color: rgba(0, 98, 255, 0.3);
}
